<template>
    <div>
      <client-accounting-category-list></client-accounting-category-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  